import axios from "axios"
import authHeader from "./jwt-token-access/auth-token-header";
import { generateSignature } from "./common";


//apply base url for axios
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://api.bitanicagames.com"
const API_URL = API_BASE_URL + "/api"
export const API_VERSION = "/v1"

const axiosIcoApi = axios.create({
  baseURL: API_URL + API_VERSION,
})

export const updateToken = token => {
  // axiosIcoApi.defaults.headers.common["Authorization"] = "Bearer " + token
}

export const updateCSRFToken = token => {
  axiosIcoApi.defaults.headers.common["csrf-token"] = token
}


export const updateLanguage = lang => {
  axiosIcoApi.defaults.headers.common['Accept-Language'] = lang;
}


// let authToken = null
let lang;
if (typeof window !== "undefined") {
  // authToken = localStorage.getItem("authToken");
  lang = localStorage.getItem("lang");
}


// updateToken(authToken || "");
updateLanguage(lang || "");

axiosIcoApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)


const checkStatusCode = (code, err) => {
  // console.log(err, "<====err")
  if (code && [403, 501, 502, 503, 500, 405, 406].includes(code)) {

    console.log(err, "<====err")
    if (axiosIcoApi.defaults.headers.common["token"] !== undefined) {
      // LogoutUser()
      throw new Error(err.response.data.message)
    } else {
      throw new Error(err.response.data.message)
    }
  } else {
    throw new Error(err.message)
  }
}

export async function get(url, config = {}) {
  // Clear existing headers to avoid sending previous nonce and signature
  delete axiosIcoApi.defaults.headers.common['x-nonce'];
  delete axiosIcoApi.defaults.headers.common['x-signature'];

  const { signature, nonce } = await generateSignature();
  if (!nonce || !signature) {
    return;
  }
  if (nonce && signature) {
    axiosIcoApi.defaults.headers.common['x-nonce'] = nonce;
    axiosIcoApi.defaults.headers.common['x-signature'] = signature;
  }

  return await axiosIcoApi
    .get(url, { ...config, withCredentials: true })
    .then(response => {
      // if (response.data.status === "success") {
      //   return response.data
      // } else {
      //   throw new Error(response.data.message)
      // }
      return response.data;
    })
    .catch(e => {
      checkStatusCode(!e.response ? null : e.response.status, e)
    })
}

export async function post(url, data, config = {}) {
  // Clear existing headers to avoid sending previous nonce and signature
  delete axiosIcoApi.defaults.headers.common['x-nonce'];
  delete axiosIcoApi.defaults.headers.common['x-signature'];

  const { signature, nonce } = await generateSignature();
  if (!nonce || !signature) {
    return;
  }
  if (nonce && signature) {
    axiosIcoApi.defaults.headers.common['x-nonce'] = nonce;
    axiosIcoApi.defaults.headers.common['x-signature'] = signature;
  }


  return await axiosIcoApi
    .post(url, data, { ...config, withCredentials: true })
    .then(response => {
      // if (response.data.status === "success") {
      //   return response.data
      // } else {
      //   throw new Error(response.data.message)
      // }
      return response.data;
    })
    .catch(e => {
      checkStatusCode(!e.response ? null : e.response.status, e)
    })
}

export async function put(url, data, config = {}) {
  // Clear existing headers to avoid sending previous nonce and signature
  delete axiosIcoApi.defaults.headers.common['x-nonce'];
  delete axiosIcoApi.defaults.headers.common['x-signature'];

  const { signature, nonce } = await generateSignature();
  if (!nonce || !signature) {
    return;
  }
  if (nonce && signature) {
    axiosIcoApi.defaults.headers.common['x-nonce'] = nonce;
    axiosIcoApi.defaults.headers.common['x-signature'] = signature;
  }

  return axiosIcoApi
    .put(url, { ...data }, { ...config, withCredentials: true })
    .then(response => {
      // if (response.data.status === "success") {
      //   return response.data
      // } else {
      //   throw new Error(response.data.message)
      // }
      return response.data;
    })
    .catch(e => {
      checkStatusCode(!e.response ? null : e.response.status, e)
    })
}

export async function del(url, config = {}) {
  // Clear existing headers to avoid sending previous nonce and signature
  delete axiosIcoApi.defaults.headers.common['x-nonce'];
  delete axiosIcoApi.defaults.headers.common['x-signature'];

  const { signature, nonce } = await generateSignature();
  if (nonce && signature) {
    axiosIcoApi.defaults.headers.common['x-nonce'] = nonce;
    axiosIcoApi.defaults.headers.common['x-signature'] = signature;
  }
  if (!nonce || !signature) {
    return;
  }
  return await axiosIcoApi
    .delete(url, { ...config, withCredentials: true })
    .then(response => {
      // if (response.data.status === "success") {
      //   return response.data
      // } else {
      //   throw new Error(response.data.message)
      // }
      return response.data;
    })
    .catch(e => {
      checkStatusCode(!e.response ? null : e.response.status, e)
    })
}

// File
export const postFile = (url, data) => {

  const formData = new FormData();

  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" }
  };

  return post(url, formData, config);
};
