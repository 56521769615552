import { combineReducers } from "@reduxjs/toolkit";

//reducers
import Auth from "./auth/slice";
import Settings from "./settings/slice";
import Game from "./game/slice";
import Tasks from "./tasks/slice";



const rootReducer = combineReducers({
  Auth,
  Settings,
  Game,
  Tasks
});

export default rootReducer;
