import React, { useEffect, useState } from "react";
import { Button, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import styles from "./wallet.module.scss";

// img
import btnc from "../../../assets/images/diamond.png";
import LinkAccountPop from "../../../components/Modals/LinkAccountPop";
import BtncHistory from "./Component/Btnc";
import CryptoHistory from "./Component/Crypto";
import { useDispatch, useSelector } from "react-redux";
import { get } from "../../../helper/api_helper";
import { CHECK_BITANICA_LINKING } from "../../../helper/url_helper";
import { checkBitanicaLinkApi } from "../../../lib/store/actions";
import useUserBalance from "../../../hooks/useUserBalance";

const Wallet = () => {
  const dispatch = useDispatch();
  const { gameEnergyLeft, btncBalance } = useUserBalance();
  const [activeTab, setActiveTab] = useState("BTNC");
  const [linkAccount, setLinkAccount] = useState();
  const handleLinkAccount = () => {
    setLinkAccount(!linkAccount);
  };
  const { user } = useSelector(({ Auth }) => ({
    user: Auth.user,
  }));

  // useEffect(() => {
  //   dispatch(checkBitanicaLinkApi({}));
  // }, []);

  return (
    <>
      <LinkAccountPop
        linkAccount={linkAccount}
        setLinkAccount={setLinkAccount}
      />
      <section className={`${styles.walletSec} wallet position-relative`}>
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="top text-center">
                <h6 className="m-0 text-white">Balance</h6>
                <div className="py-2 d-flex align-items-center justify-content-center">
                  <img
                    src={btnc}
                    alt=""
                    className="img-fluid object-fit-contain flex-shrink-0 me-1"
                    style={{ height: 25 }}
                  />
                  <h2
                    className="m-0 fw-bold text-white"
                    style={{ fontSize: 19 }}
                  >
                    {btncBalance || 0}
                  </h2>
                </div>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <Tab.Container id="left-tabs-example" defaultActiveKey="BTNC">
                <Nav
                  activeKey={activeTab}
                  accessKey=""
                  variant="pills"
                  className={`${styles.tab} justify-content-center inline-flex rounded-4 mx-auto p-1 PillsTab`}
                  style={{
                    background: " #101017",
                    border: "1px solid #2f6a8791",
                    maxWidth: "max-content",
                  }}
                >
                  <Nav.Item>
                    <Nav.Link
                      className={`${styles.link} d-flex align-items-center justify-content-center  fw-bold`}
                      eventKey="BTNC"
                      onClick={() => setActiveTab("BTNC")}
                    >
                      BTNC
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={`${styles.link} d-flex align-items-center justify-content-center  fw-bold`}
                      eventKey="CRYPTO"
                      onClick={() => setActiveTab("CRYPTO")}
                    >
                      CRYPTO
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content
                  className="pt-3"
                  style={{ fontSize: 14, lineHeight: "20px" }}
                >
                  <Tab.Pane eventKey="BTNC">
                    {activeTab === "BTNC" && <BtncHistory />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="CRYPTO">
                    {activeTab === "CRYPTO" && <CryptoHistory />}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Wallet;
