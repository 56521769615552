// src/context/ToastContext.js
import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';

// Create the Toast Context
const ToastContext = createContext();

// Define toast types
const TOAST_TYPES = {
    SUCCESS: 'success',
    INFO: 'info',
    ERROR: 'error',
};

// Toast Provider Component
export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    // Function to add a new toast
    const addToast = useCallback((message, type = TOAST_TYPES.INFO, duration = null) => {
        const id = Date.now() + Math.random(); // Unique ID
        setToasts((prevToasts) => [...prevToasts, { id, message, type, duration }]);
    }, []);

    // Function to remove a toast by ID
    const removeToast = useCallback((id) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    }, []);

    return (
        <ToastContext.Provider value={{ addToast, removeToast }}>
            {children}
            {/* Integrated Toast Component */}
            <ToastContainer toasts={toasts} removeToast={removeToast} />
        </ToastContext.Provider>
    );
};

// Custom Hook to use the Toast Context
export const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};
const ToastContainer = ({ toasts, removeToast }) => {
    useEffect(() => {
        // Loop over the toasts and handle removal after the duration
        const timers = toasts.map((toast) => {
            if (toast.duration) {
                const timer = setTimeout(() => {
                    removeToast(toast.id);
                }, toast.duration);

                // Return the timeout ID so we can clear it later
                return timer;
            }
            return null;
        });

        // Cleanup function to clear timeouts if the component re-renders or unmounts
        return () => {
            timers.forEach((timer) => {
                if (timer) {
                    clearTimeout(timer);
                }
            });
        };
    }, [toasts, removeToast]);

    return (
        <div style={styles.toastContainer}>
            {toasts.map((toast) => (
                <div key={toast.id} style={{ ...styles.toast, ...styles[`toast_${toast.type}`] }}>
                    <div style={styles.toastIcon}>
                        {getIcon(toast.type)}
                    </div>
                    <div style={styles.toastMessage}>{toast.message}</div>
                    {/* Optionally bring back the close button */}
                    <button
                        style={styles.toastCloseButton}
                        onClick={() => removeToast(toast.id)}
                        aria-label="Close Toast"
                    >
                        &times;
                    </button>
                </div>
            ))}
        </div>
    );
};


// Helper function to get icons based on toast type
const getIcon = (type) => {
    switch (type) {
        case TOAST_TYPES.SUCCESS:
            return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5002 1.64575C5.61014 1.64575 1.646 5.6099 1.646 10.4999C1.646 15.3899 5.61014 19.3541 10.5002 19.3541C15.3902 19.3541 19.3543 15.3899 19.3543 10.4999C19.3543 5.6099 15.3902 1.64575 10.5002 1.64575ZM0.0834961 10.4999C0.0834961 4.74695 4.74719 0.083252 10.5002 0.083252C16.2531 0.083252 20.9168 4.74695 20.9168 10.4999C20.9168 16.2528 16.2531 20.9166 10.5002 20.9166C4.74719 20.9166 0.0834961 16.2528 0.0834961 10.4999ZM14.6985 7.51693C15.0035 7.82203 15.0035 8.31669 14.6985 8.62179L9.83735 13.4829C9.53224 13.788 9.03756 13.788 8.73245 13.4829L6.30192 11.0523C5.99683 10.7472 5.99683 10.2526 6.30192 9.94752C6.60703 9.64242 7.10168 9.64242 7.40678 9.94752L9.28495 11.8256L13.5936 7.51693C13.8987 7.21183 14.3934 7.21183 14.6985 7.51693Z" fill="white" />
            </svg>; // You can replace with SVGs or icon components
        case TOAST_TYPES.INFO:
            return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <circle cx="10" cy="10" r="9.2" stroke="white" stroke-width="1.6" />
                <g clip-path="url(#clip0_1_5596)">
                    <path d="M9.04424 15V8.22618H10.4595V15H9.04424ZM9.75872 7.3056C9.48392 7.3056 9.25034 7.21858 9.05798 7.04454C8.87478 6.86134 8.78318 6.62776 8.78318 6.3438C8.78318 6.05984 8.87478 5.83084 9.05798 5.6568C9.25034 5.4736 9.48392 5.382 9.75872 5.382C10.0427 5.382 10.2763 5.4736 10.4595 5.6568C10.6427 5.83084 10.7343 6.05984 10.7343 6.3438C10.7343 6.62776 10.6427 6.86134 10.4595 7.04454C10.2763 7.21858 10.0427 7.3056 9.75872 7.3056Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_1_5596">
                        <rect width="16" height="16" fill="white" transform="translate(2 2)" />
                    </clipPath>
                </defs>
            </svg>;
        case TOAST_TYPES.ERROR:
            return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <circle cx="10" cy="10" r="9.2" stroke="white" stroke-width="1.6" />
                <path d="M13.25 6.75L6.75 13.25M6.75 6.75L13.25 13.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>;
        default:
            return '';
    }
};

// Inline Styles for the Toast Components
const styles = {
    toastContainer: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        left: 0,
        margin: "0 auto",
        zIndex: 9999,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: "center",
    },
    toast: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#333',
        color: '#fff',
        fontWeight: 500,
        fontFamily: "Space Grotesk",
        padding: '12px 16px',
        height: "75px",
        borderRadius: '0px',
        width: '430px',
        // boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        // animation: 'fadeIn 0.5s, fadeOut 0.5s 4.5s',
        position: 'relative',
        transition: 'opacity 0.5s, transform 0.5s',
    },
    toast_success: {
        backgroundColor: '#40C4AC',
    },
    toast_info: {
        backgroundColor: '#17a2b8',
    },
    toast_error: {
        backgroundColor: '#dc3545',
    },
    toastIcon: {
        marginRight: '10px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
        // fontSize: '20px',
    },
    // toastMessage: {
    //     flex: 1,
    // },
    toastCloseButton: {
        background: 'none',
        border: 'none',
        color: '#fff',
        fontSize: '16px',
        cursor: 'pointer',
        marginLeft: '10px',
    },
};
