import { useEffect, useState } from 'react';
import moment from 'moment-timezone';

const useEightHourCountdown = (timezone = 'Etc/GMT-4') => { // Use GMT+4 (which is 'Etc/GMT-4' in moment-timezone)
    const [hours, setHours] = useState('00');  // Two digits for hours
    const [minutes, setMinutes] = useState('00');  // Two digits for minutes
    const [seconds, setSeconds] = useState('00');  // Two digits for seconds

    useEffect(() => {
        const updateCountdown = () => {
            const now = moment.tz(new Date(), timezone);
            const nextTargetTime = calculateNextInterval(now, timezone);
            const duration = moment.duration(nextTargetTime.diff(now));

            // Ensure duration is not negative
            if (duration.asMilliseconds() >= 0) {
                setHours(String(Math.floor(duration.asHours())).padStart(2, '0'));  // Pad hours
                setMinutes(String(duration.minutes()).padStart(2, '0'));  // Pad minutes
                setSeconds(String(duration.seconds()).padStart(2, '0'));  // Pad seconds
            } else {
                setHours('00');
                setMinutes('00');
                setSeconds('00');
            }
        };

        const calculateNextInterval = (currentTime, timezone) => {
            const currentHour = currentTime.hours();
            const currentMinute = currentTime.minutes();

            let nextTargetTime;

            // Determine the next target time based on the current hour
            if (currentHour < 8 || (currentHour === 8 && currentMinute === 0)) {
                // Countdown from 12:00 AM to 8:00 AM
                nextTargetTime = moment.tz(currentTime, timezone).startOf('day').add(8, 'hours');
            } else if (currentHour < 16) {
                // Countdown from 8:00 AM to 4:00 PM
                nextTargetTime = moment.tz(currentTime, timezone).startOf('day').add(16, 'hours');
            } else if (currentHour < 24) {
                // Countdown from 4:00 PM to 12:00 AM
                nextTargetTime = moment.tz(currentTime, timezone).startOf('day').add(1, 'days').startOf('day');
            } else {
                // After 12:00 AM, next countdown starts from 12:00 AM to 8:00 AM
                nextTargetTime = moment.tz(currentTime, timezone).startOf('day').add(1, 'days').add(8, 'hours');
            }

            return nextTargetTime;
        };

        // Update the countdown every second
        const timerId = setInterval(updateCountdown, 1000);

        // Initial call to set the countdown immediately
        updateCountdown();

        // Cleanup on unmount
        return () => clearInterval(timerId);
    }, [timezone]);

    return { hours, minutes, seconds };
};

export default useEightHourCountdown;
