import CryptoJS from "crypto-js";
import { getSSMParameter } from "./secretManager";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { store } from "../lib/store";

export const isMobile = () => {
  return typeof window === "object" && window.innerWidth <= 768; // Adjust the threshold as needed
};

export function isDesktop() {
  const userAgent = navigator.userAgent.toLowerCase();

  // List of common desktop and mobile indicators
  const desktopPlatforms = ["macintosh", "windows", "linux"];
  const mobileIndicators = ["android", "iphone", "ipad", "mobile"];

  // Check for desktop indicators and ensure no mobile indicators are present
  const isDesktopPlatform = desktopPlatforms.some(platform => userAgent.includes(platform));
  const isMobilePlatform = mobileIndicators.some(platform => userAgent.includes(platform));

  return isDesktopPlatform && !isMobilePlatform;
}


export const generateSignature = async () => {
  try {

    const secret =
      process.env.REACT_APP_HMAC_SECRET || (await getSSMParameter("HMAC_SECRET")); // Should not be exposed in production

    const nonce = Date.now().toString(); // Unique nonce for every request

    // Generate HMAC using CryptoJS
    const hmac = CryptoJS.HmacSHA256(nonce, secret);
    const signature = hmac.toString(CryptoJS.enc.Hex);

    return {
      signature,
      nonce,
    };
  } catch (err) {
    return {
      signature: null,
      nonce: null,
    };
  }
};

export const gameArray = {
  gameHistoryId: "65e5780457749e542ec97449",
  lineCount: 0,
  crystalsClaimed: 60,
  nftsClaimed: 0,
  gameData: [
    {
      isClicked: false,
      row: [
        {
          column: 1,
          value: 2,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 1,
        },
        {
          column: 2,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 1,
        },
        {
          column: 3,
          value: 9,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 1,
        },
        {
          column: 4,
          value: 5,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 1,
        },
      ],
    },
    {
      isClicked: false,
      row: [
        {
          column: 1,
          value: 19,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 2,
        },
        {
          column: 2,
          value: 13,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 2,
        },
        {
          column: 3,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 2,
        },
        {
          column: 4,
          value: 15,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 2,
        },
      ],
    },
    {
      isClicked: false,
      row: [
        {
          column: 1,
          value: 26,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 3,
        },
        {
          column: 2,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 3,
        },
        {
          column: 3,
          value: 22,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 3,
        },
        {
          column: 4,
          value: 23,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 3,
        },
      ],
    },
    {
      isClicked: false,
      row: [
        {
          column: 1,
          value: 40,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 4,
        },
        {
          column: 2,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 4,
        },
        {
          column: 3,
          value: 33,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 4,
        },
        {
          column: 4,
          value: 37,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 4,
        },
      ],
    },
    {
      isClicked: false,
      row: [
        {
          column: 1,
          value: 0.00001,
          valueType: "coin",
          coinName: "Bitcoin",
          coinSymbol: "BTC",
          coinIcon: "https://cryptologos.cc/logos/bitcoin-btc-logo.png",
          isOpened: false,
          lineCount: 5,
        },
        {
          column: 2,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 5,
        },
        {
          column: 3,
          value: 49,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 5,
        },
        {
          column: 4,
          value: 43,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 5,
        },
      ],
    },
    {
      isClicked: false,
      row: [
        {
          column: 1,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 6,
        },
        {
          column: 2,
          value: 56,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 6,
        },
        {
          column: 3,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 6,
        },
        {
          column: 4,
          value: 59,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 6,
        },
      ],
    },
    {
      isClicked: false,
      row: [
        {
          column: 1,
          value: 70,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 7,
        },
        {
          column: 2,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 7,
        },
        {
          column: 3,
          value: 61,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 7,
        },
        {
          column: 4,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 7,
        },
      ],
    },
    {
      isClicked: false,
      row: [
        {
          column: 1,
          value: 79,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 8,
        },
        {
          column: 2,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 8,
        },
        {
          column: 3,
          value: 75,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 8,
        },
        {
          column: 4,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 8,
        },
      ],
    },
    {
      isClicked: false,
      row: [
        {
          column: 1,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 9,
        },
        {
          column: 2,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 9,
        },
        {
          column: 3,
          value: 85,
          valueType: "BTNC",
          coinName: "Bitanica Coin",
          coinSymbol: "BTNC",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/btnc.png",
          isOpened: false,
          lineCount: 9,
        },
        {
          column: 4,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 9,
        },
      ],
    },
    {
      isClicked: false,
      row: [
        {
          column: 1,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 10,
        },
        {
          column: 2,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 10,
        },
        {
          column: 3,
          value: 0.00001,
          valueType: "coin",
          coinName: "Ethereum",
          coinSymbol: "ETH",
          coinIcon: "https://cryptologos.cc/logos/ethereum-eth-logo.png",
          isOpened: false,
          lineCount: 10,
        },
        {
          column: 4,
          value: "bomb",
          valueType: "bomb",
          coinName: "bomb",
          coinSymbol: "bomb",
          coinIcon: "https://minehunt-new.s3.us-east-2.amazonaws.com/bomb.png",
          isOpened: false,
          lineCount: 10,
        },
      ],
    },
  ],
};

export function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const isNumeric = (value) => {
  return /^-?\d+$/.test(value);
};
export function copyToClipboard(text) {
  return new Promise((resolve, reject) => {
    try {
      if (navigator.clipboard) {
        // Use the Clipboard API for modern browsers
        navigator.clipboard.writeText(text)
          .then(() => {
            resolve("Successfully Copied");
          })
          .catch((err) => {
            console.error("Clipboard API failed: ", err);
            reject("Failed to copy text.");
          });
      } else {
        // Fallback for older browsers
        const textarea = document.createElement("textarea");
        textarea.value = text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
          const successful = document.execCommand("copy");
          if (successful) {
            resolve("Text copied successfully!");
          } else {
            reject("Failed to copy text.");
          }
        } catch (err) {
          console.error("Fallback copy failed: ", err);
          reject("Failed to copy text.");
        }
        document.body.removeChild(textarea);
      }
    } catch (err) {
      console.error("Unexpected error: ", err);
      reject("An unexpected error occurred.");
    }
  });
}



export const getCookie = (name) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) {
    return match[2];
  }
  return null;
};

// Unified toast function
export const showToast = (message, type, options = {}) => {
  const icons = {
    success: <SuccessIcon />,  // Custom success icon
    error: <ErrorIcon />,      // Custom error icon
    info: <InfoIcon />,
  };

  // Check the type and show the corresponding toast
  toast.dismiss();
  if (type === 'success') {
    toast.success(message, {
      icon: icons.success,
      autoClose: 4000, className: 'mineHuntToast',
      ...options,             // Spread any additional options
    });
  } else if (type === 'error') {
    toast.error(message, {
      icon: icons.error,
      autoClose: 4000, className: 'mineHuntToast ErrorToast',
      ...options,             // Spread any additional options
    });
  } else if (type === 'info') {
    toast.success(message, {
      icon: icons.info,
      autoClose: 6000, className: 'mineHuntToast ErrorInfo',
      ...options,             // Spread any additional options
    });
  }
  else {
    console.error("Invalid toast type. Use 'success' or 'error'.");
  }
};

// Custom Success Icon component
const SuccessIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5002 1.64575C5.61014 1.64575 1.646 5.6099 1.646 10.4999C1.646 15.3899 5.61014 19.3541 10.5002 19.3541C15.3902 19.3541 19.3543 15.3899 19.3543 10.4999C19.3543 5.6099 15.3902 1.64575 10.5002 1.64575ZM0.0834961 10.4999C0.0834961 4.74695 4.74719 0.083252 10.5002 0.083252C16.2531 0.083252 20.9168 4.74695 20.9168 10.4999C20.9168 16.2528 16.2531 20.9166 10.5002 20.9166C4.74719 20.9166 0.0834961 16.2528 0.0834961 10.4999ZM14.6985 7.51693C15.0035 7.82203 15.0035 8.31669 14.6985 8.62179L9.83735 13.4829C9.53224 13.788 9.03756 13.788 8.73245 13.4829L6.30192 11.0523C5.99683 10.7472 5.99683 10.2526 6.30192 9.94752C6.60703 9.64242 7.10168 9.64242 7.40678 9.94752L9.28495 11.8256L13.5936 7.51693C13.8987 7.21183 14.3934 7.21183 14.6985 7.51693Z" fill="white" />
  </svg>
);

// Custom Error Icon component
const ErrorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <circle cx="10" cy="10" r="9.2" stroke="white" stroke-width="1.6" />
    <g clip-path="url(#clip0_1_5596)">
      <path d="M13.25 6.75L6.75 13.25M6.75 6.75L13.25 13.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1_5596">
        <rect width="16" height="16" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);


// Custom Info Icon component
const InfoIcon = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.744 5.656C10.5413 5.656 10.3707 5.59733 10.232 5.48C10.104 5.352 10.04 5.192 10.04 5C10.04 4.72267 10.1573 4.48267 10.392 4.28C10.6267 4.06667 10.8827 3.96 11.16 3.96C11.3627 3.96 11.528 4.024 11.656 4.152C11.7947 4.26933 11.864 4.424 11.864 4.616C11.864 4.89333 11.7467 5.13867 11.512 5.352C11.2773 5.55467 11.0213 5.656 10.744 5.656ZM8.696 15.096C8.472 15.096 8.29067 15.032 8.152 14.904C8.01333 14.776 7.944 14.6 7.944 14.376C7.944 14.28 7.96 14.1787 7.992 14.072L9.288 8.824C9.29867 8.792 9.304 8.74933 9.304 8.696C9.304 8.6 9.272 8.52533 9.208 8.472C9.15467 8.41867 9.08 8.392 8.984 8.392C8.61067 8.392 8.12533 8.58933 7.528 8.984C7.96533 8.49333 8.40267 8.104 8.84 7.816C9.27733 7.528 9.66667 7.384 10.008 7.384C10.232 7.384 10.4133 7.448 10.552 7.576C10.6907 7.704 10.76 7.88 10.76 8.104C10.76 8.18933 10.744 8.29067 10.712 8.408L9.4 13.656C9.37867 13.72 9.368 13.7787 9.368 13.832C9.368 14.0027 9.48533 14.088 9.72 14.088C10.0933 14.088 10.5787 13.8907 11.176 13.496C10.7387 13.9867 10.3013 14.376 9.864 14.664C9.42667 14.952 9.03733 15.096 8.696 15.096Z" fill="white" />
    <circle cx="9" cy="10" r="8.5" stroke="#FEFEFE" />
  </svg>

);



let audioCache = {};
let allSoundsPreloaded = false;

// Function to preload multiple audio files at once and track when all are loaded
export const preloadSounds = (soundFiles, callback) => {
  let soundsToLoad = soundFiles.length;
  soundFiles.forEach((sound) => {
    const audio = new Audio(sound);
    audio.preload = 'auto';
    audio.addEventListener("canplaythrough", () => {
      audioCache[sound] = audio;
      soundsToLoad -= 1; // Decrement the count as each sound is loaded
      if (soundsToLoad === 0) {
        allSoundsPreloaded = true; // All sounds are now preloaded
        if (callback) callback(); // Call the callback function if provided
      }
    });
    audio.load(); // Begin loading the audio file
  });
};

// Function to play the sound if all sounds are preloaded
export const playSound = (sound) => {
  try {
    if (allSoundsPreloaded) {
      const state = store.getState();
      if (state?.Auth?.user?.enabledSound) {
        const audio = audioCache[sound];
        if (audio) {
          audio.currentTime = 0;
          audio.play();
        } else {
          console.log(`Sound not preloaded: ${sound}`);
        }
      }
    } else {
      console.log('Sounds are still preloading. Please wait.');
    }
  } catch (err) { }

};
