import React from 'react'

import loader from "../../assets/images/loading.gif"

const LoaderCstm = () => {
    return (
        <>
            <div className="d-flex align-items-center justify-content-center w-100 position-fixed" style={{ top: 0, right: 0, zIndex: 999, height: "100vh", background: "#0000008a" }}>
                <img src={loader} alt="" className="img-fluid object-fit-contain" style={{ height: 45 }} />
            </div>
        </>
    )
}

export default LoaderCstm