// src/context/ToastContext.js
import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { gameArray } from '../../helper/common';

// Create the Toast Context
const GameContext = createContext();
// Toast Provider Component
export const GameProvider = ({ children }) => {
    const [game, SetGame] = useState({ ...gameArray, gameData: gameArray.gameData.reverse() });
    const [isGameStart, setGameStart] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [gameLoading, setGameLoading] = useState(true);

    const resetGame = () => {
        SetGame({ ...gameArray, gameData: gameArray.gameData.reverse() });
    }
    return (
        <GameContext.Provider value={{ gameLoading, setGameLoading, game, SetGame, isGameStart, isLoading, setGameStart, setIsLoading, resetGame }}>
            {children}
        </GameContext.Provider>
    );
};

// Custom Hook to use the Toast Context
export const useGameContext = () => {
    const context = useContext(GameContext);
    if (!context) {
        throw new Error('useGameContext must be used within a GameProvider');
    }
    return context;
};