import { createSlice } from "@reduxjs/toolkit";
import * as Act from "./actions";


const initialState = {
  loading: false,
  tasksList: [],
  totalTasks: 0,
  taskDetails: null,
  loadingCompleteTask: false,
  roadMapsList: [],
  totalRoadMaps: 0
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState: { ...initialState },
  reducers: {
    setTaskDetails(state, action) {
      const { data } = action.payload;
      state.taskDetails = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(Act.tasksListApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(Act.tasksListApi.fulfilled, (state, action) => {
        state.loading = false;
        state.tasksList =
          action.payload?.filter?.page === 1
            ? action.payload.data
            : [...state.tasksList, ...action.payload.data];
        state.totalTasks = action.payload.totalcount;
      })
      .addCase(Act.tasksListApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(Act.completeTaskApi.pending, (state, action) => {
        state.loadingCompleteTask = true;
      })
      .addCase(Act.completeTaskApi.fulfilled, (state, action) => {
        state.loadingCompleteTask = false;
        state.tasksList = state.tasksList.map((item) => item?._id === action.payload?.data?._id ? action.payload?.data : item)
      })
      .addCase(Act.completeTaskApi.rejected, (state, action) => {
        state.loadingCompleteTask = false;
      })
      .addCase(Act.getRoadmapsListApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(Act.getRoadmapsListApi.fulfilled, (state, action) => {
        state.loading = false;
        state.roadMapsList = action.payload.data;
        state.totalRoadMaps = action.payload.totalcount
      })
      .addCase(Act.getRoadmapsListApi.rejected, (state, action) => {
        state.loading = false;
      })

  }
});

export default tasksSlice.reducer;