import { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client'; // Import Socket.IO client

// Define your socket server URL
const SOCKET_URL = 'https://api.bitanicagames.com';

// Custom hook to manage socket connection
const useSocket = () => {
    const [socketConnect, setSocketConnect] = useState(false);
    const [socket, setSocket] = useState(null);

    // Connect to socket server
    const connectSocket = useCallback(() => {
        const socketInstance = io(SOCKET_URL);

        socketInstance.on('connect', () => {
            console.log('Socket connected with ID:', socketInstance.id);
            setSocketConnect(true);
        });

        socketInstance.on('disconnect', () => {
            console.log('Socket disconnected:', socketInstance.id);
            setSocketConnect(false);
        });

        setSocket(socketInstance);

        return socketInstance;
    }, []);

    // Disconnect the socket when the component is unmounted
    useEffect(() => {
        const socketInstance = connectSocket();

        return () => {
            socketInstance.disconnect();
            console.log('Socket disconnected');
        };
    }, [connectSocket]);



    return { socket, socketConnect };
};

export default useSocket;
