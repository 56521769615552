import { useEffect, useState } from 'react';
import useSocket from './useSocket'; // Import your existing useSocket hook

const TEST_URL = 'https://www.google.com/favicon.ico'; // A small, reliable resource to test internet connectivity
const TIMEOUT = 3000; // Set timeout for 3 seconds

const useNetworkState = () => {
    const [state, setState] = useState({
        online: navigator.onLine,
        internet: false,
        loading: false, // New loading state
    });

    const { socket, socketConnect } = useSocket();

    useEffect(() => {
        const handleStateChange = () => {
            const online = navigator.onLine;
            setState(prevState => ({
                ...prevState,
                online,
                internet: online ? prevState.internet : false, // Set internet to false immediately if offline
            }));

            if (online) {
                // Check internet connectivity immediately
                // checkInternetConnection();
            }
        };

        // Event listeners for online/offline status
        window.addEventListener('online', handleStateChange);
        window.addEventListener('offline', handleStateChange);

        // Initial check on mount
        handleStateChange();

        return () => {
            window.removeEventListener('online', handleStateChange);
            window.removeEventListener('offline', handleStateChange);
        };
    }, []);

    useEffect(() => {
        if (socket) {
            // Check if socket is connected to determine internet access
            setState(prevState => ({
                ...prevState,
                internet: socketConnect,
            }));

            socket.on('connect', () => {
                console.log('Socket connected: internet is working');
                setState(prevState => ({
                    ...prevState,
                    internet: true,
                }));
            });

            socket.on('disconnect', () => {
                console.log('Socket disconnected: no internet access');
                setState(prevState => ({
                    ...prevState,
                    internet: false,
                }));
            });
        }
    }, [socket, socketConnect]);

    // Function to check if internet is reachable with timeout
    const checkInternetConnection = async () => {
        setState(prevState => ({ ...prevState, loading: true })); // Set loading to true

        // Create a promise that rejects after the timeout
        const timeoutPromise = new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Timeout')), TIMEOUT)
        );

        try {
            const response = await Promise.race([
                fetch(TEST_URL, { method: 'HEAD', cache: 'no-store' }),
                timeoutPromise // Race between fetch and timeout
            ]);

            if (response.ok) {
                setState(prevState => ({
                    ...prevState,
                    internet: true,
                }));
            } else {
                setState(prevState => ({
                    ...prevState,
                    internet: false,
                }));
            }
        } catch (error) {
            console.error('Internet connection test failed:', error);
            setState(prevState => ({
                ...prevState,
                internet: false,
            }));
        } finally {
            setState(prevState => ({ ...prevState, loading: false })); // Set loading to false
        }
    };

    return state;
};

export default useNetworkState;
