import { SSMClient, GetParameterCommand } from "@aws-sdk/client-ssm";
import CryptoJS from 'crypto-js';


export async function getSSMParameter(parameterName) {

    try {
        let ssmClientData = {};

        // Use environment variable for region, fallback to 'us-east-2'
        ssmClientData.region = process.env.REACT_APP_SSM_REGION || "us-east-2";

        // Check if credentials are set (for local dev), otherwise rely on server's IAM role
        if (process.env.REACT_APP_SSM_ACCESS_KEY && process.env.REACT_APP_SSM_SECRET_KEY) {
            ssmClientData.credentials = {
                accessKeyId: process.env.REACT_APP_SSM_ACCESS_KEY,
                secretAccessKey: process.env.REACT_APP_SSM_SECRET_KEY
            };
        }
        const ssm = new SSMClient(ssmClientData);
        const params = {
            Name: parameterName,
            WithDecryption: true
        };
        const command = new GetParameterCommand(params);
        // console.log("command", command)

        const response = await ssm.send(command);
        return response?.Parameter?.Value;
    } catch (err) {
        // Handle error
        console.error('Error getting SSM parameter:', err);
        return null;
    }
}

// Convert hex string to WordArray (used by crypto-js)
function hexToWordArray(hex) {
    return CryptoJS.enc.Hex.parse(hex);
}

export async function encrypt(text) {
    try {
        const keyHex = await getSSMParameter("ENCRYPT_SECRET_KEY");
        const ivHex = await getSSMParameter("ENCRYPT_SECRET_IV");
        const key = CryptoJS.enc.Hex.parse(keyHex);  // Parse the key from hex
        const iv = CryptoJS.enc.Hex.parse(ivHex);    // Parse the IV from hex

        const encrypted = CryptoJS.AES.encrypt(text, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return encrypted.ciphertext.toString(CryptoJS.enc.Hex);  // Return encrypted string in hex format
    }
    catch (err) {
        console.log(err, "err");
    }
}

export async function decrypt(encryptedText) {
    try {
        const keyHex = await getSSMParameter("ENCRYPT_SECRET_KEY");
        const ivHex = await getSSMParameter("ENCRYPT_SECRET_IV");
        const key = CryptoJS.enc.Hex.parse(keyHex);  // Parse the key from hex
        const iv = CryptoJS.enc.Hex.parse(ivHex);    // Parse the IV from hex

        const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedText);
        const encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);

        const decrypted = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return decrypted.toString(CryptoJS.enc.Utf8);  // Return decrypted string in utf8
    }
    catch (err) {
        console.log(err, "err");
    }
}