import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import styles from "./game.module.scss";

// img
import current from "../../../assets/images/current.png";
import diamond from "../../../assets/images/diamond.png";
import hexDownFade from "../../../assets/images/hexDownFade.svg";
import hexUpFade from "../../../assets/images/hexUpFade.svg";
import hexActive from "../../../assets/images/hexActive.svg";
import hexWrong from "../../../assets/images/hexWrong.svg";
import hexRight from "../../../assets/images/hexRight.svg";
import hexDef from "../../../assets/images/hexDef.svg";
// import rightHex from "../../../assets/images/rightHex.svg";
import hexfade from "../../../assets/images/hexFade.svg";
// import leftHex from "../../../assets/images/leftHex.svg";
import HowToPlayPop from "../../../components/Modals/HowToPlay";
import BuyPop from "../../../components/Modals/BuyPop";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { gameArray, playSound, preloadSound, preloadSounds, showToast, wait } from "../../../helper/common";
import {
  activeGameDetail,
  buyEnergy,
  gameComplete,
  gameStart,
  getSettings,
  getUserProfile,
  updateGameApi,
  updateProfile,
  userSessionLogout,
} from "../../../lib/store/actions";
import { decrypt, encrypt } from "../../../helper/secretManager";
import LoaderCstm from "../../../components/Loader";
import CongratulationPop from "../../../components/Modals/congratulationPop";
import GameOverPop from "../../../components/Modals/GameOverPop";
import useUserBalance from "../../../hooks/useUserBalance";
import { useGameContext } from "../../../context/GameContext";
import useNetworkState from "../../../hooks/useNetworkState";

const Game = () => {
  const { game, SetGame, isGameStart, setGameStart, isLoading, setIsLoading, gameLoading, resetGame } = useGameContext();
  const navigate = useNavigate();
  const elementRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [playInst, setPlayInst] = useState();
  const [congrats, setCongrats] = useState(false);
  const [buy, setBuy] = useState();
  const { user: userData } = useSelector(({ Auth }) => Auth);
  const [refresh, setRefresh] = useState(false);
  const [gameUpdateLoading, setGameUpdateLoading] = useState(false);
  const { gameEnergyLeft, setGameEnergyLeft, btncBalance, setBtncBalance } = useUserBalance();
  const { settings } = useSelector(({ Settings }) => Settings);
  const { loading } = useSelector(({ Game }) => Game);
  const [cryptoWinningCong, setCryptoWinningCong] = useState(null);
  const [audioLoad, setAudioLoad] = useState(false);
  const [scrollToNum, setScrollToNum] = useState(1);
  const [initailScrollCount, setInitialScrollCount] = useState(0);
  const [isToastActive, setIsToastActive] = useState(false);


  const dispatch = useDispatch();
  const network = useNetworkState();
  // Reference to store the timeout ID
  function assignAndScroll(idNumber) {
    try {
      const section = document.getElementById(`number-${idNumber}`);
      const container = document.getElementById('game-contain'); // Replace with your container's ID

      if (section && container) {
        // Get the bounding rectangle of the section
        const sectionRect = section.getBoundingClientRect();

        const viewportHeight = container.clientHeight;
        // Check if the section is already fully visible in the viewport
        const isFullyVisible = sectionRect.top >= 100 && sectionRect.bottom <= viewportHeight;

        if (!isFullyVisible) {
          section.style.scrollMarginTop = '80px'; // Reset margin for other ids
          // Scroll into view with smooth behavior if not fully visible
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } catch (err) {
      console.error('Error scrolling to section:', err);
    }
  }

  useEffect(() => {
    if (game) {
      if (initailScrollCount > 1) {
        return;
      }
      setTimeout(() => {
        setInitialScrollCount(pre => pre + 1);
        assignAndScroll(game?.lineCount || 1);
        setScrollToNum(game?.lineCount || 1);
      }, 1000)
    }
  }, [game]);


  useEffect(() => {
    try {

      const sounds = [
        "/sound/startround.mp3",
        "/sound/claim.mp3",
        "/sound/bombendgame.mp3",
        "/sound/diamondevenline.mp3",
        "/sound/diamondoddline.mp3"
      ];
      preloadSounds(sounds, () => {
        setAudioLoad(true);
      });

    } catch (err) { }
  }, [])


  const startGame = async (e) => {
    try {
      e?.preventDefault();
      if (!network?.online) {
        if (!isToastActive) {
          setIsToastActive(true);
          showToast("Please check your internet connection!", "error");
          setTimeout(() => {
            setIsToastActive(false);
          }, 3000);
        }
        return;
      }
      if (isGameStart) return;
      if (loading || isLoading || !audioLoad || gameUpdateLoading) return;

      /*reset the game on start click start */
      resetGame();
      assignAndScroll(1)
      setScrollToNum(1);
      /*reset the game on start click end */
      setIsLoading(true);
      dispatch(
        gameStart({
          data: {},
          callBack: async (err, res) => {
            if (err) {
              setIsLoading(false);
              setGameStart(false);
              resetGame();
              assignAndScroll(1)
              setScrollToNum(1);
              return;
            }
            setGameStart(true);
            if (!res?.data) return;
            let updatedData = { ...res.data, gameData: [] };

            let decoded = updatedData?.gameData
              ? await decrypt(res.data?.gameData)
              : [];
            decoded = decoded ? JSON.parse(decoded) : [];
            updatedData = {
              ...updatedData,
              gameData: decoded,
            };
            playSound("/sound/startround.mp3");
            SetGame({
              ...updatedData,
              gameData: updatedData?.gameData.reverse(),
            });

            assignAndScroll(1)
            setScrollToNum(1);
            setTimeout(() => {
              setIsLoading(false);
            }, 4000);
          },
        })
      );
    } catch (err) {
      setIsLoading(false);
    }
  };


  const claimBtncs = async (needToEnd, updatedGame, type) => {
    setIsLoading(true);
    try {
      if (!updatedGame?.gameData) return;
      let gameData = [...updatedGame.gameData];
      gameData.reverse();
      if (needToEnd === 2 && type === "bomb") {

      } else if (needToEnd === 2 && type !== "bomb") {
        playSound("/sound/claim.mp3");
      } else {
        playSound("/sound/claim.mp3");
      }
      dispatch(
        gameComplete({
          data: {
            _id: updatedGame?._id,
            lineCount: updatedGame?.lineCount,
            gameData: await encrypt(JSON.stringify(gameData)),
            winnings: updatedGame?.winnings,
          },
          callBack: (err, res) => {
            if (err) {
              setTimeout(() => {
                setIsLoading(false);
              }, 5000);
              if (parseInt(err?.status_code) === 406) {
                //game already ended
                setGameStart(false);
                resetGame();
                assignAndScroll(1)
                setScrollToNum(1);
              }
              return;
            }

            let gameDataUpdateClick = [];
            for (var i = 0;i < updatedGame.gameData.length;i++) {
              // show the game history when end or claim
              gameDataUpdateClick.push({
                ...updatedGame.gameData[i],
                isClicked: true,
              });
            }
            updatedGame.gameData = gameDataUpdateClick;
            setGameEnergyLeft(res?.data?.energyBalance); // manage energy used count
            setBtncBalance(res?.data?.btncBalance);
            SetGame(updatedGame);
            setRefresh(!refresh);
            setTimeout(() => {
              setGameStart(false); // reset to start button
            }, 3000);

            // showSuccess(res?.message)
            // getProfile();
            setTimeout(() => {
              setIsLoading(false);
            }, 3000);

          },
        })
      );
    } catch (err) {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const updateGameMove = (needToEnd, updatedGame, type) => {
    return new Promise(async (resolve, reject) => {
      // Return a Promise
      try {
        setGameUpdateLoading(true);
        if (!updatedGame?.gameData) return;
        let gameData = [...updatedGame.gameData];
        gameData.reverse();

        dispatch(
          updateGameApi({
            data: {
              _id: updatedGame?._id,
              lineCount: updatedGame?.lineCount,
              gameData: await encrypt(JSON.stringify(gameData)),
              winnings: updatedGame?.winnings,
            },
            callBack: (err, res) => {
              setGameUpdateLoading(false);
              if (err) {
                if (parseInt(err?.status_code) === 406) {
                  //game already ended
                  setGameStart(false);
                  resetGame();
                  assignAndScroll(1)
                  setScrollToNum(1);
                }

              } else {
                if (needToEnd == 2) {
                  claimBtncs(needToEnd, updatedGame, type);
                }
                resolve(true); // Resolve on success
              }
            },
          })
        );
      } catch (err) {
        setGameUpdateLoading(false);
        reject(false); // Reject on exception
      }
    });
  };

  useEffect(() => {
    if (game && elementRef.current) {
      setHeight(elementRef.current.clientHeight);
    }
  }, [game]);

  const handleClick = async (rowIndex, rowItemIndex, item) => {
    try {
      if (!network?.online) {
        if (!isToastActive) {
          setIsToastActive(true);
          showToast("Please check your internet connection!", "error");
          setTimeout(() => {
            setIsToastActive(false);
          }, 3000);
        }
        return;
      }
      if (!game?._id) return;
      if (isLoading) return;
      // if (loading) return;
      if (!isGameStart) return;
      if (item.valueType === "bomb") {
        playSound("/sound/bombendgame.mp3");
        let updatedGame = { ...game };
        let updatedGameData = updatedGame.gameData;
        updatedGameData = updatedGameData?.map((item, index) => {
          let uItem = { ...item };
          if (index === rowIndex) {
            uItem.isClicked = true;
            uItem.row = uItem.row.map((rowItem, index2) => {
              let rowI = { ...rowItem };
              if (index2 === rowItemIndex) {
                return { ...rowI, isOpened: true };
              }
              return rowI;
            });
            return uItem;
          }
          return item;
        });


        updatedGame.gameData = updatedGameData;
        setRefresh(!refresh);
        updatedGame.lineCount = updatedGame.lineCount + 1;
        claimBtncs(2, updatedGame, "bomb");
        SetGame(updatedGame);
        setTimeout(() => {
          setGameStart(false); // reset to start button
        }, 3000);
      } else {
        if (item?.lineCount % 2 === 0) {
          playSound("/sound/diamondevenline.mp3");
        } else {
          playSound("/sound/diamondoddline.mp3");
        }

        let updatedGame = { ...game };
        let updatedGameData = updatedGame.gameData;
        updatedGameData = updatedGameData?.map((item, index) => {
          let uItem = { ...item };
          if (index === rowIndex) {
            uItem.isClicked = true;
            uItem.row = uItem.row.map((rowItem, index2) => {
              let rowI = { ...rowItem };
              if (index2 === rowItemIndex) {
                return { ...rowI, isOpened: true };
              }
              return rowI;
            });
            return uItem;
          }
          return uItem;
        });
        updatedGame.gameData = updatedGameData;
        let WinningItem = updatedGameData[rowIndex].row[rowItemIndex];
        const foundWinning = updatedGame.winnings.find(
          (item) => item.symbol === WinningItem.coinSymbol
        );
        if (foundWinning) {
          let copyFoundWinning = { ...foundWinning };
          copyFoundWinning.amount += WinningItem.value;
          let updatedWinnings = updatedGame.winnings.map((item) =>
            item.symbol === copyFoundWinning.symbol ? copyFoundWinning : item
          );
          updatedGame.winnings = updatedWinnings;
        } else {
          updatedGame.winnings = [
            ...updatedGame.winnings,
            {
              name: WinningItem.coinName,
              symbol: WinningItem.coinSymbol,
              icon: WinningItem.coinIcon,
              amount: WinningItem.value,
            },
          ];
        }
        updatedGame.lineCount = updatedGame.lineCount + 1;

        SetGame(updatedGame);
        const needToEnd = parseInt(updatedGame.lineCount) === 10 ? 2 : 1;

        assignAndScroll(scrollToNum + 1);
        setScrollToNum(scrollToNum + 1);
        if (WinningItem.coinSymbol !== "BTNC") {
          setCongrats(true);
          //show congratulation  popup.
          setCryptoWinningCong({
            name: WinningItem.coinName,
            symbol: WinningItem.coinSymbol,
            icon: WinningItem.coinIcon,
            amount: WinningItem.value,
          });
        }

        const response = await updateGameMove(
          needToEnd,
          updatedGame,
          "crypto"
        );

      }
    } catch (err) {
      console.log("Error inside game", err);
    }
  };


  const onCompleteEnergyBuy = (data) => {
    if (!data) return;
    setGameEnergyLeft(data?.energyBalance);
    setBtncBalance(data?.btncBalance);
  };

  const onFinishCongrats = () => {
    setCryptoWinningCong(null);
    setCongrats(false);
  };

  const calculateTotalBTNC = useCallback(() => {
    return game?.winnings?.reduce((total, currentItem) => {
      if (
        currentItem &&
        currentItem.symbol === "BTNC" &&
        typeof currentItem.amount === "number"
      ) {
        return total + currentItem.amount;
      }
      return total;
    }, 0);
  }, [game?.winnings]); // The useCallback hook will memoize this function based on the winnings array

  const totalBTNC = calculateTotalBTNC();

  // const onPlayAgainGame = () => {
  //   if (isGameStart) return;
  //   setShowGameOverPop(false);
  //   startGame();
  // }
  useEffect(() => {
    // 
    document.body.classList.add('includeHeader');

    // Cleanup: Remove the class when the component unmounts asdfasdf
    return () => {
      document.body.classList.remove('includeHeader');
    };
  }, []);

  const redirectToLeaderBorad = () => {
    if (settings?.leaderboard?.leaderboardStatus === "active") {
      navigate("/leaderboard");
    } else {
      if (!isToastActive) {
        setIsToastActive(true);
        showToast("Leaderboard is not active.", "info");
        setTimeout(() => {
          setIsToastActive(false);
        }, 3000);
      }

    }
  }
  return (
    <>
      {/* {showGameOverPop && <GameOverPop show={showGameOverPop} onHide={() => {
        setShowGameOverPop(false);
      }} onPlayAgain={onPlayAgainGame} />
      } */}

      <CongratulationPop
        winningData={cryptoWinningCong}
        congrats={congrats}
        setCongrats={setCongrats}
        onFinish={() => onFinishCongrats()}
      />
      <HowToPlayPop playInst={playInst} setPlayInst={setPlayInst} />
      <BuyPop buy={buy} setBuy={setBuy} onFinish={onCompleteEnergyBuy} />
      {isLoading && <LoaderCstm />}
      <section className={`${styles.gameSec} gameSec position-relative `}>
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div
                id="game-contain"
                className={`${styles.gameBoxWrpper} gameBoxWrpper allow-scroll`}
              >
                <div className={`${styles.gameBox} w-100`}>
                  <div className="d-flex align-items-center gap-10">
                    <ul
                      className={`${styles.countList} d-none list-unstyled ps-0 mb-0 position-relative`}
                    >
                      {game?.gameData &&
                        game?.gameData.length > 0 &&
                        game?.gameData.map((item, key) => (
                          <li
                            key={key}
                            className={`${styles.level} ${(!item?.isClicked &&
                              game?._id &&
                              game?.gameData &&
                              game?.gameData?.length -
                              1 -
                              game?.lineCount) === key && styles.active
                              } d-flex align-items-center`}
                            style={{ height: height / 1.23, gap: 8 }}
                          >
                            <span
                              className=" fw-bold position-relative"
                              style={{ width: 20 }}
                            >
                              {game?.gameData?.length - key}
                            </span>
                            <span className="icn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="4"
                                height="4"
                                viewBox="0 0 4 4"
                                fill="none"
                              >
                                <rect
                                  x="0.000244141"
                                  width="4"
                                  height="4"
                                  rx="2"
                                  fill="#506380"
                                />
                              </svg>
                            </span>
                          </li>
                        ))}
                    </ul>
                    <ul
                      className={`${styles.gameOptionWrp} list-unstyled ps-0 mb-0`}
                    >
                      <li
                        className="position-relative pe-0 d-flex align-items-center justify-content-center"
                        style={{ opacity: 0.5, paddingLeft: 40, paddingBottom: 12, marginTop: -40 }}
                      >
                        <div className={`${styles.listCount} opacity-0 position-absolute text-center d-flex align-items-center`}>  <span
                          className=" fw-bold position-relative "
                          style={{ width: 20 }}
                        ></span>
                          <span className="icn ms-">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="4"
                              height="4"
                              viewBox="0 0 4 4"
                              fill="none"
                            >
                              <rect
                                x="0.000244141"
                                width="4"
                                height="4"
                                rx="2"
                                fill="#506380"
                              />
                            </svg>
                          </span> </div>
                        <div
                          className={`${styles.option} position-relative d-flex align-items-center flex-wrap justify-content-center`}
                        >
                          <span
                            className={`${styles.hexIcn} hexIcn position-absolute`}
                          >
                            <img
                              src={hexUpFade}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </span>
                        </div>
                        <div
                          className={`${styles.option} position-relative d-flex align-items-center flex-wrap justify-content-center`}
                        >
                          <span
                            className={`${styles.hexIcn} hexIcn position-absolute`}
                          >
                            <img
                              src={hexUpFade}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </span>
                        </div>
                        <div
                          className={`${styles.option} position-relative d-flex align-items-center flex-wrap justify-content-center`}
                        >
                          <span
                            className={`${styles.hexIcn} hexIcn position-absolute`}
                          >
                            <img
                              src={hexUpFade}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </span>
                        </div>
                        <div
                          className={`${styles.option} position-relative d-flex align-items-center flex-wrap justify-content-center`}
                        >
                          <span
                            className={`${styles.hexIcn} hexIcn position-absolute`}
                          >
                            <img
                              src={hexUpFade}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </span>
                        </div>
                        <div
                          className={`${styles.option} position-relative d-flex align-items-center flex-wrap justify-content-center`}
                        >
                          <span
                            className={`${styles.hexIcn} hexIcn position-absolute`}
                          >
                            <img
                              src={hexUpFade}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </span>
                        </div>
                      </li>
                      <li
                        className="position-relative pt-2 d-none align-items-center justify-content-center"
                        style={{ opacity: 0 }}
                      >
                        <div className={`${styles.listCount} opacity-0 position-absolute text-center d-flex align-items-center`}>  <span
                          className=" fw-bold position-relative "
                          style={{ width: 20 }}
                        ></span>
                          <span className="icn ms-">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="4"
                              height="4"
                              viewBox="0 0 4 4"
                              fill="none"
                            >
                              <rect
                                x="0.000244141"
                                width="4"
                                height="4"
                                rx="2"
                                fill="#506380"
                              />
                            </svg>
                          </span> </div>
                        <div
                          className={`${styles.option} position-relative d-flex align-items-center flex-wrap justify-content-center`}
                        >
                          <span
                            className={`${styles.hexIcn} hexIcn position-absolute`}
                          >
                            <img
                              src={hexUpFade}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </span>
                        </div>
                        <div
                          className={`${styles.option} position-relative d-flex align-items-center flex-wrap justify-content-center`}
                        >
                          <span
                            className={`${styles.hexIcn} hexIcn position-absolute`}
                          >
                            <img
                              src={hexUpFade}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </span>
                        </div>
                        <div
                          className={`${styles.option} position-relative d-flex align-items-center flex-wrap justify-content-center`}
                        >
                          <span
                            className={`${styles.hexIcn} hexIcn position-absolute`}
                          >
                            <img
                              src={hexUpFade}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </span>
                        </div>
                        <div
                          className={`${styles.option} position-relative d-flex align-items-center flex-wrap justify-content-center`}
                        >
                          <span
                            className={`${styles.hexIcn} hexIcn position-absolute`}
                          >
                            <img
                              src={hexUpFade}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </span>
                        </div>
                        <div
                          className={`${styles.option} position-relative d-flex align-items-center flex-wrap justify-content-center`}
                        >
                          <span
                            className={`${styles.hexIcn} hexIcn position-absolute`}
                          >
                            <img
                              src={hexUpFade}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </span>
                        </div>
                      </li>
                      {game?.gameData &&
                        game?.gameData.length > 0 &&
                        game?.gameData?.map((item, index1) => (
                          <li
                            className="position-relative d-flex align-items-center justify-content-center"
                            ref={elementRef}
                            id={`number-${game?.gameData?.length - index1}`}
                          >
                            <div className={`${styles.listCount} ${(!item?.isClicked &&
                              game?._id &&
                              game?.gameData &&
                              game?.gameData?.length -
                              1 -
                              game?.lineCount) === index1 && styles.active
                              }
                             position-absolute text-center d-flex align-items-center`}>  <span
                                className=" fw-bold position-relative"
                                style={{ width: 20 }}
                              >{game?.gameData?.length - index1}</span>  <span className="icn ms-">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="4"
                                  height="4"
                                  viewBox="0 0 4 4"
                                  fill="none"
                                >
                                  <rect
                                    x="0.000244141"
                                    width="4"
                                    height="4"
                                    rx="2"
                                    fill="#506380"
                                  />
                                </svg>
                              </span> </div>
                            {index1 % 2 == 0 && (
                              <div
                                className={`${styles.option}
                                    `}
                              >
                                {/* <img
                                  src={leftHex}
                                  alt=""
                                  className="img-fluid w-100"
                                /> */}
                                {leftHex}
                              </div>
                            )}
                            {item?.row &&
                              item?.row.map((data, index2) => {
                                return (
                                  <div
                                    key={index2}

                                    className={`${styles.option} ${(!item?.isClicked &&
                                      game?._id &&
                                      game?.gameData &&
                                      game?.gameData?.length -
                                      1 -
                                      game?.lineCount) === index1 &&
                                      styles.active
                                      } ${data?.isOpened &&
                                        data?.valueType === "bomb"
                                        ? styles.wrongAns
                                        : null
                                      } ${data?.isOpened &&
                                        data?.valueType !== "bomb"
                                        ? styles.rightAns
                                        : ""
                                      } 

 ${!data?.isOpened && item?.isClicked && game?.gameData?.length - 1 < game?.lineCount <= index1 ? "opacity-50" : ""
                                      }                                      position-relative d-flex align-items-center flex-wrap justify-content-center`}
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      if (
                                        (game?.gameData &&
                                          game?.gameData?.length -
                                          1 -
                                          game?.lineCount) !== index1
                                      ) {
                                        return;
                                      }
                                      handleClick(index1, index2, data);
                                    }}
                                  >
                                    <span
                                      className={`${styles.hexIcn} hexIcn position-absolute`}
                                    >
                                      {(!item?.isClicked &&
                                        game?._id &&
                                        game?.gameData &&
                                        game?.gameData?.length -
                                        1 -
                                        game?.lineCount) === index1 ? (
                                        <img
                                          src={hexActive}
                                          alt=""
                                          className="img-fluid w-100 activated-hex"
                                        />
                                      ) : data?.isOpened &&
                                        data?.valueType !== "bomb" ? (
                                        <>
                                          <img
                                            src={hexRight}
                                            alt=""
                                            className="img-fluid w-100"
                                          />
                                          <span className={`${styles.hexShadow} hexShadow position-absolute h-auto mx-auto`}
                                            style={{
                                              left: "50%",
                                              top: "50%",
                                            }}>
                                            {blueShadow}
                                          </span>
                                        </>
                                      ) : data?.isOpened &&
                                        data?.valueType === "bomb" ? (
                                        <>
                                          <img
                                            src={hexWrong}
                                            alt=""
                                            className="img-fluid w-100"
                                          />
                                          <span className={`${styles.hexShadow} hexShadow position-absolute h-auto mx-auto`}
                                            style={{
                                              left: "50%",
                                              top: "50%",
                                            }}>
                                            {redShadow}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={item?.isClicked && game?.gameData?.length - 1 < game?.lineCount <= index1 ? hexfade : hexDef}
                                            alt=""
                                            className="img-fluid w-100"
                                          />

                                        </>
                                      )
                                      }
                                    </span>

                                    {item.isClicked && (
                                      <>
                                        {typeof data?.value === "number" &&
                                          !isNaN(data?.value) && (
                                            <span
                                              className={`${styles.value} text-white text-center d-block w-100`}
                                            // style={{ right: 2, bottom: 2 }}
                                            >
                                              {data?.value?.toString().length >
                                                4
                                                ? data?.value
                                                : data?.value}
                                            </span>
                                          )}
                                      </>
                                    )}
                                    {item.isClicked && (
                                      <>
                                        {data?.valueType === "bomb" && (
                                          <img
                                            src={data?.coinIcon}
                                            alt=""
                                            className="img-fluid object-fit-contain"
                                            style={{ height: "70%" }}
                                          />
                                        )}
                                        {data?.valueType === "BTNC" && (
                                          <img
                                            src={data?.coinIcon}
                                            alt=""
                                            className="img-fluid object-fit-contain"
                                            style={{ height: "50%" }}
                                          />
                                        )}
                                        {data?.valueType === "coin" && (
                                          <img
                                            src={data?.coinIcon}
                                            alt=""
                                            className="img-fluid object-fit-contain"
                                            style={{ height: "50%" }}
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                );
                              })}
                            {index1 % 2 !== 0 && (
                              <div
                                className={`${styles.option}
                                    `}
                              >
                                {/* <img
                                  src={rightHex}
                                  alt=""
                                  className="img-fluid w-100"
                                /> */}
                                {rightHex}
                              </div>
                            )}
                          </li>
                        ))}
                      <li
                        className="position-relative pt-2 d-flex align-items-center justify-content-center"
                        style={{ opacity: .5 }}
                      >
                        <div className={`${styles.listCount} opacity-0 position-absolute text-center d-flex align-items-center`}>  <span
                          className=" fw-bold position-relative "
                          style={{ width: 20 }}
                        ></span>
                          <span className="icn ms-">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="4"
                              height="4"
                              viewBox="0 0 4 4"
                              fill="none"
                            >
                              <rect
                                x="0.000244141"
                                width="4"
                                height="4"
                                rx="2"
                                fill="#506380"
                              />
                            </svg>
                          </span> </div>
                        <div
                          className={`${styles.option} position-relative d-flex align-items-center flex-wrap justify-content-center`}
                        >
                          <span
                            className={`${styles.hexIcn} hexIcn position-absolute`}
                          >
                            <img
                              src={hexDownFade}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </span>
                        </div>
                        <div
                          className={`${styles.option} position-relative d-flex align-items-center flex-wrap justify-content-center`}
                        >
                          <span
                            className={`${styles.hexIcn} hexIcn position-absolute`}
                          >
                            <img
                              src={hexDownFade}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </span>
                        </div>
                        <div
                          className={`${styles.option} position-relative d-flex align-items-center flex-wrap justify-content-center`}
                        >
                          <span
                            className={`${styles.hexIcn} hexIcn position-absolute`}
                          >
                            <img
                              src={hexDownFade}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </span>
                        </div>
                        <div
                          className={`${styles.option} position-relative d-flex align-items-center flex-wrap justify-content-center`}
                        >
                          <span
                            className={`${styles.hexIcn} hexIcn position-absolute`}
                          >
                            <img
                              src={hexDownFade}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </span>
                        </div>
                        <div
                          className={`${styles.option} position-relative d-flex align-items-center flex-wrap justify-content-center`}
                        >
                          <span
                            className={`${styles.hexIcn} hexIcn position-absolute`}
                          >
                            <img
                              src={hexDownFade}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="12" className={`${styles.ctcWrpper} py-2`} style={{
              position: "fixed",
              bottom: 75,
              maxWidth: 430,
              zIndex: 999,
              background: "#0c0c0c"
            }}>
              <div
                className={`${styles.ctcBtn} d-flex align-items-end justify-content-between`}
              >
                {/* <Link
                  to={"/leaderboard"}
                  // onClick={handleLeaderBoard}
                  className={`${styles.btn} d-flex align-items-center btn justify-content-center`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M20.5588 29.606H21.9265V22.9772H28.7647C29.2683 22.9772 29.6765 23.4012 29.6765 23.9242V29.606H31.0441C31.2959 29.606 31.5 29.818 31.5 30.0795V31.0265C31.5 31.288 31.2959 31.5 31.0441 31.5H0.955882C0.704105 31.5 0.5 31.288 0.5 31.0265V30.0795C0.5 29.818 0.704105 29.606 0.955882 29.606H2.32353V21.5567C2.32353 21.0337 2.73174 20.6098 3.23529 20.6098H10.0735V29.606H11.4412V17.2953C11.4412 16.7723 11.8494 16.3484 12.3529 16.3484H19.6471C20.1506 16.3484 20.5588 16.7723 20.5588 17.2953V29.606ZM13.8804 11.2366C12.9868 11.7245 12.0616 11.017 12.2309 9.99184L12.6357 7.54046L10.9209 5.80438C10.1979 5.07247 10.5599 3.93996 11.5509 3.79039L13.9207 3.43273L14.9806 1.20239C15.4274 0.262104 16.5762 0.269643 17.0194 1.20239L18.0793 3.43273L20.4491 3.79039C21.4481 3.94117 21.7963 5.07833 21.0791 5.80438L19.3643 7.54046L19.7691 9.99184C19.9398 11.0253 19.0061 11.7206 18.1196 11.2366L16 10.0792L13.8804 11.2366Z"
                      fill="#67F5FF"
                    />
                  </svg>
                </Link> */}
                <div className="">
                  <p className="ps-1 m-0 text-white">LEADERBOARD</p>
                  <Button
                    onClick={() => redirectToLeaderBorad()}
                    className="d-flex align-items-center justify-content-center w-100 fw-bold"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="34"
                      height="33"
                      viewBox="0 0 34 33"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1_2101)">
                        <path
                          d="M8.51764 29.897L10.3111 20.8255L10.4203 20.2729L10.0043 19.893L3.17416 13.6573L12.3553 12.5624L12.9152 12.4956L13.1478 11.982L16.964 3.55733L20.8424 11.9502L21.0789 12.462L21.6391 12.5246L30.8318 13.5518L24.0481 19.8402L23.635 20.2231L23.7484 20.7749L25.6096 29.8304L17.5394 25.3209L17.0472 25.0459L16.5572 25.3246L8.51764 29.897Z"
                          stroke="white"
                          stroke-width="2"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_2101">
                          <rect
                            width="33"
                            height="33"
                            fill="white"
                            transform="translate(0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </Button>
                </div>
                <div className="">
                  <p className="ps-1 m-0 text-white">SNATCH</p>
                  <Button className="text-white d-flex align-items-center justify-content-center w-100 fw-bold">
                    {totalBTNC || 0}
                  </Button>
                </div>

                {/* {isGameStart ? (
                  <Button
                    disabled={loading || isLoading || !audioLoad || gameUpdateLoading || !isGameStart}
                    onClick={handleEndGame}
                    className="d-flex align-items-center justify-content-center w-100 fw-bold text-white"
                    style={{ background: "#ED4032", maxWidth: 170 }}
                  >
                    STOP
                  </Button>
                ) : ( */}
                <Button
                  disabled={loading || isLoading || !audioLoad || gameUpdateLoading || isGameStart}
                  onClick={startGame}
                  className={`${styles.gameBtn} d-flex align-items-center justify-content-center w-100 fw-bold text-white`}
                  style={{ background: "#242AFC", maxWidth: 170 }}
                >
                  START
                </Button>
                {/* )} */}

              </div>
            </Col>
          </Row>
        </Container>
      </section >
    </>
  );
};
export default Game;




const redShadow = <svg xmlns="http://www.w3.org/2000/svg" width="71" height="80" viewBox="0 0 71 80" fill="none">
  <g filter="url(#filter0_f_7_685)">
    <path d="M35.7512 5.18576L35.2805 4.9137L34.8099 5.18576L5.52934 22.1111L5.05952 22.3827V22.9254V56.7762V57.3188L5.52934 57.5904L34.8099 74.5158L35.2805 74.7878L35.7512 74.5158L65.0317 57.5904L65.5016 57.3188V56.7762V22.9254V22.3827L65.0317 22.1111L35.7512 5.18576Z" stroke="#ED4032" stroke-width="1.88096" />
  </g>
  <defs>
    <filter id="filter0_f_7_685" x="0.357217" y="0.0652246" width="69.8461" height="79.5707" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="1.88096" result="effect1_foregroundBlur_7_685" />
    </filter>
  </defs>
</svg>

const blueShadow = <svg xmlns="http://www.w3.org/2000/svg" width="71" height="80" viewBox="0 0 71 80" fill="none">
  <g filter="url(#filter0_f_7_689)">
    <path d="M35.7512 5.18576L35.2805 4.9137L34.8099 5.18576L5.52934 22.1111L5.05952 22.3827V22.9254V56.7762V57.3188L5.52934 57.5904L34.8099 74.5158L35.2805 74.7878L35.7512 74.5158L65.0317 57.5904L65.5016 57.3188V56.7762V22.9254V22.3827L65.0317 22.1111L35.7512 5.18576Z" stroke="#232AF7" stroke-width="1.88096" />
  </g>
  <defs>
    <filter id="filter0_f_7_689" x="0.357217" y="0.0652246" width="69.8461" height="79.5707" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="1.88096" result="effect1_foregroundBlur_7_689" />
    </filter>
  </defs>
</svg>

const leftHex = <svg
  xmlns="http://www.w3.org/2000/svg"
  width="63"
  height="73"
  viewBox="0 0 62 72"
  fill="none"
>
  <path
    d="M30.9795 0L62 18V54L30.9795 72L0 54V18L30.9795 0Z"
    fill="url(#paint0_linear_20_4618)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_20_4618"
      x1="55.2188"
      y1="26.4906"
      x2="-3.43536"
      y2="37.1983"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#13131B" />
      <stop offset="1" stop-color="#13131B" stop-opacity="0" />
    </linearGradient>
  </defs>
</svg>

const rightHex = <svg
  xmlns="http://www.w3.org/2000/svg"
  width="63"
  height="73"
  viewBox="0 0 62 72"
  fill="none"
>
  <path
    d="M31 0L62 17.9897V53.9691L31 72L0 53.9691V17.9897L31 0Z"
    fill="url(#paint0_linear_20_4617)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_20_4617"
      x1="-10.5"
      y1="37.2597"
      x2="80"
      y2="37.2597"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#0F0F13" />
      <stop offset="1" stop-color="#13131B" stop-opacity="0" />
    </linearGradient>
  </defs>
</svg>