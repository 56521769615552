/* Instruments */
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as URL from "../../../helper/url_helper";
import { get } from "../../../helper/api_helper";
import { showToast } from "../../../helper/common";
import { authSlice } from "../auth/slice";

export const getSettings = createAsyncThunk(
  "getSettings",
  async ({ data, callBack }, Thunk) => {
    try {
      const response = await get(URL.GET_SETTINGS);
      if (response?.status_code === 405) {

        showToast(response?.message, "error");
        callBack && callBack(response?.message, null);
        Thunk.dispatch(authSlice.actions.clearUserDetails())
        document.cookie = "";
        window.location.reload();
        return Thunk.rejectWithValue(response?.message);

      }
      if (response.status === "failure") {
        showToast(response?.message, "error");
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response?.data);
      return response.data;
    } catch (error) {
      console.log(error, "<===error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);



