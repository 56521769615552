import React from "react";
import { Container } from "react-bootstrap";
// css
import styles from "./Maintenance.module.scss";

const Maintenance = () => {
    return (
        <>
            <section
                className={`${styles.page} text-center py-5 position-relative d-flex align-items-center justify-content-center`}
            >
                <Container>
                    <p >Under Maintenance</p>
                </Container>
            </section >
        </>
    );
};

export default Maintenance;
