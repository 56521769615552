import React, { useState } from "react";
import { Button, Modal, Nav, Spinner, Tab } from "react-bootstrap";
import styles from "./congratsPop.module.scss";

// img
import p1 from "../../../assets/images/current.png";
import diamond from "../../../assets/images/diamond.png";
import congratsImg from "../../../assets/images/congratsPop.png";
import { playSound } from "../../../helper/common";

const CongratulationPop = ({ winningData, congrats, setCongrats, onFinish }) => {

  const handledHide = () => {
    setCongrats(!congrats);
    onFinish();
  };

  return (
    <>
      <Modal
        show={congrats}
        onHide={handledHide}
        backdrop="true"
        centered
        className={`${styles.buyPop} bottomPop`}
        keyboard={false}
      >
        <div className="modalWrpper">

          <Modal.Body>
            <Button
              onClick={handledHide}
              variant="transparent"
              className="border-0 p-0 position-absolute"
              style={{ top: 15, right: 15 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.0024 26.0048C20.1834 26.0048 26.0048 20.1834 26.0048 13.0024C26.0048 5.82137 20.1834 0 13.0024 0C5.82137 0 0 5.82137 0 13.0024C0 20.1834 5.82137 26.0048 13.0024 26.0048ZM13.0062 14.1485L18.1747 19.3251L19.3205 18.1711L14.152 13.0026L19.3205 7.83412L18.1747 6.68827L13.0062 11.8568L7.83767 6.68015L6.6837 7.83412L11.8603 13.0026L6.6837 18.1711L7.83767 19.3251L13.0062 14.1485Z"
                  fill="#506380"
                />
              </svg>
            </Button>
            <div className="text-center">
              <img src={congratsImg} alt="" className="img-fluid w-100" />
              <div className="d-flex align-items-center justify-content-center mt-4 gap-10">
                <h2 className={`${styles.price} m-0 fw-bold`}>{winningData?.amount}<span className="icn"><img style={{ height: 60 }} src={winningData?.icon} alt="" className="img-fluid object-fit-contain" /></span></h2>
              </div>
            </div>
            <div className="my-4 text-center">
              <Button
                onClick={() => handledHide()}
                style={{ maxWidth: 290 }}
                className="d-inline-flex text-uppercase align-items-center justify-content-center fw-bold commonBtn border-0"
              >
                Continue
              </Button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default CongratulationPop;
