import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBitanicaBtncBalanceApi } from '../lib/store/actions';

function useUserBalance() {
    const { user: userData } = useSelector(({ Auth }) => Auth);
    const [gameEnergyLeft, setGameEnergyLeft] = useState(userData?.energyBalance || 0);
    const [btncBalance, setBtncBalance] = useState(userData?.btncBalance || 0);
    const dispatch = useDispatch();
    // You can also add a useEffect here to update the values when userData changes
    useEffect(() => {
        if (userData) {
            setGameEnergyLeft(userData.energyBalance || 0);
            setBtncBalance(userData.btncBalance || 0);
        }
    }, [userData]);

    const SyncBitanicaBalance = async () => {
        dispatch((getBitanicaBtncBalanceApi({
            callBack: (err, data) => {
                if (err) return;
                setBtncBalance(data?.balance);
            }
        })));
    }

    return { gameEnergyLeft, setGameEnergyLeft, btncBalance, setBtncBalance, SyncBitanicaBalance };
}

export default useUserBalance;
