import { useState, useEffect } from 'react';
import moment from 'moment-timezone';

const useLbCountdown = () => {
    const [timeLeft, setTimeLeft] = useState({ hours: '00', minutes: '00', seconds: '00' });

    // Helper function to get the next Sunday at 11:59 PM in GMT+4

    const getResetTime = () => {
        const now = moment();  // Get the current moment

        // Calculate the days until next Sunday
        const daysUntilNextSunday = (7 - now.day()) % 7;  // Days until next Sunday

        // Move to next Sunday and set the time to 11:59:59.999 PM (23:59:59.999)
        const nextSunday = now.add(daysUntilNextSunday, 'days').endOf('day');

        // Convert the time to GMT+4
        const nextSundayGMT4 = nextSunday.tz('GMT+4');  // Set the timezone to GMT+4

        return nextSundayGMT4.toDate();  // Return as a JavaScript Date object
    };

    // Helper function to get current time in GMT+4

    const getGMT4Time = () => {
        const gmt4Time = moment.tz('GMT+4');  // Convert the current time to GMT+4
        return gmt4Time.toDate();  // Return as JavaScript Date object
    };



    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = getGMT4Time();  // Get current time in GMT+4
            const resetTime = getResetTime();  // Get next Sunday at 11:59 PM GMT+4
            console.log("now", now);
            console.log("reset", resetTime);
            // Calculate the difference in milliseconds between the reset time and now
            const durationInMilliseconds = resetTime - now;
            if (durationInMilliseconds > 0) {
                // Calculate total remaining hours, minutes, and seconds
                const totalHours = Math.floor(durationInMilliseconds / (1000 * 60 * 60)); // Total hours
                const hours = String(totalHours).padStart(2, '0'); // Remaining hours
                const minutes = String(Math.floor((durationInMilliseconds / (1000 * 60)) % 60)).padStart(2, '0'); // Minutes
                const seconds = String(Math.floor((durationInMilliseconds / 1000) % 60)).padStart(2, '0'); // Seconds

                setTimeLeft({ hours, minutes, seconds });  // Update the time left
            } else {
                // Reset to zero if the target time has passed
                setTimeLeft({ hours: '00', minutes: '00', seconds: '00' });
            }
        };

        calculateTimeLeft();  // Initial calculation on mount

        // Update every second
        const timer = setInterval(calculateTimeLeft, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(timer);
    }, []);  // Empty dependency array to only run once on mount

    return timeLeft;  // Return the time left (hours, minutes, seconds)
};

export default useLbCountdown;
