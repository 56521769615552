import React, { useState, useEffect, useRef } from 'react';

const useReverseTimer = (callback) => {
  const [itimeLeft, setTimeLeft] = useState(0);
  const hasFinished = useRef(false); // Ref to track if the timer has finished

  const startTimer = (initialTime) => {
    if (hasFinished.current) {
      // If the timer already finished, reset it
      hasFinished.current = false; // Reset the finished state
      setTimeLeft(initialTime); // Reset the timer to the new initial time
    } else {
      setTimeLeft(initialTime); // Set the initial time when the timer starts
    }

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime - 1 <= 0) {
          clearInterval(timerId);
          hasFinished.current = true; // Mark the timer as finished
          callback(); // Trigger callback when timer reaches 0
          return 0;    // Set time left to 0
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup timer on component unmount or when the timer is reset
    return () => clearInterval(timerId);
  };

  const timeLeft = itimeLeft.toString().padStart(2, '0'); // Format as 2-digit string

  return { timeLeft, startTimer };
};

export default useReverseTimer;
